var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.htmlHeading
      ? _c("h2", {
          staticClass: "slide-heading",
          domProps: { innerHTML: _vm._s(_vm.htmlHeading) },
        })
      : _c("h2", { staticClass: "slide-heading" }, [
          _vm._v("\n    " + _vm._s(_vm.slideHeading) + "\n    "),
          _c(
            "span",
            [
              _vm.toolTip
                ? _c("fa-icon", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value: _vm.toolTip,
                        expression: "toolTip",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                    staticClass: "question-circle pl-1",
                    attrs: { icon: "question-circle", size: "xs" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }