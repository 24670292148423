<template>
  <div>
    <h2 v-if="htmlHeading"
        class="slide-heading"
        v-html="htmlHeading"
    />

    <h2 v-else class="slide-heading">
      {{ slideHeading }}
      <span>
        <fa-icon
          v-if="toolTip"
          v-b-popover.hover.top="toolTip"
          class="question-circle pl-1"
          icon="question-circle"
          size="xs"
        />
      </span>
    </h2>

    <br>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'SlideHeading',
  props: {
    heading: String,
    htmlHeading: {
      type: String,
      default: null,
    },
    toolTip: {
      type: String,
      default: null,
    },
  },
  computed: {
    slideHeading () {
      return DOMPurify.sanitize(this.heading, {
        ALLOWED_TAGS: ['a', 'strong', 'em', 'p', 'br', 'div', 'span', 'li'],
        ALLOWED_ATTR: ['href', 'target', 'rel'],
      })
    },
  },
}
</script>

<style lang="scss">
$black1: #231F20;

h2.slide-heading {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: 2.125em;
  font-weight: 700;
  color: $black1;
  line-height: 1.36;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

button.submit-btn-width {
  background: $ct-ui-primary !important;

  &:hover {
    background: $ct-ui-primary-dark !important;
    border-color: $ct-ui-primary !important;
  }
}

@media only screen and (max-width: 767px) {
  .slide-heading {
    font-size: 1.5em;
  }
}
</style>
